<template>
  <div v-once>
    <!-- <ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-9107487734392446"
     data-ad-slot="1443478648"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins> -->
    <hr>
    <div class="copyright text-center">
      &copy; Copyright {{ new Date().getFullYear() }}. TershiXia.
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
    // (adsbygoogle = window.adsbygoogle || []).push({})
  }
}
</script>
